import React from 'react'
import about2 from '../../images/about/about2.svg'
import about3 from '../../images/about/about3.svg'
import about4 from '../../images/about/about4.svg'
function AboutInfo() {
  const data = [
    {
      id: 1000,
      title: 'Hedeflerimiz',
      text: "Commersia ekibi olarak, misyonumuz müşterilerimize Amerika'da iş yapma süreçlerinde güvenilir bir rehber olmak ve en iyi hizmeti sunarak işlerini başarılı bir şekilde yürütmelerine yardımcı olmaktır. Uzman ekibimiz, Amerika'da şirket kurulumundan Amazon hesabı açmaya, banka hesapları açmaktan yeniden satış sertifikası alınmasına kadar geniş bir hizmet yelpazesi sunmaktadır. Müşteri memnuniyetine verdiğimiz önem ve edindiğimiz tecrübe ile işletmelerin Amerika'da başarılı olmalarına destek olmaktan gurur duyuyoruz.",
      image: about2,
    },
    {
      id: 1001,
      title: 'Misyonumuz',
      text: "Müşterilerimize değer katan, güvenilir ve etkili çözümler sunarak iş süreçlerini kolaylaştırmayı ve başarılı olmalarını sağlamayı hedefliyoruz. Uzman ekibimiz, her müşterinin ihtiyaçlarına özel çözümler üretmek için çalışmaktadır. Amerika'da iş yaparken karşılaşılan karmaşık adımları anlamak ve en iyi stratejileri belirlemek için uzmanlığımızı kullanıyoruz. Müşteri memnuniyetine verdiğimiz önem ve işimize duyduğumuz tutku, her zaman en iyiyi sunma çabamızın temelidir. Müşterilerimizin iş süreçlerini kolaylaştırmak için kararlı bir şekilde çalışmaya devam ediyoruz.",
      image: about3,
    },
    {
      id: 1002,
      title: 'Vizyonumuz',
      text: "Commersia ekibi olarak, e-ticaret alanında öncü bir danışmanlık firması olmayı ve müşterilere benzersiz hizmetlerle fark yaratmayı hedefliyoruz. Uzman ekibimiz, e-ticaretin dinamik dünyasında en iyi çözümleri sunabilmek için sürekli olarak kendini geliştirmekte ve yenilikleri takip etmektedir. Amerika'da iş yapma süreçlerinde uzmanlaşmış bir ekip olarak, müşterilerin başarılı olmalarına destek olmak için özelleştirilmiş hizmetler sunmayı amaçlıyoruz. Müşteri odaklı yaklaşımımız, sektördeki en iyi uygulamaları ve stratejileri benimsememizde bize rehberlik ediyor. Müşterilerin işlerini büyütmelerine ve rekabet avantajı elde etmelerine yardımcı olmak için kararlıyız.",
      image: about4,
    },
  ]

  return (
    <div className="flex flex-row justify-center px-4 sm:px-14 lg:px-28  ">
      <div className="  w-full bg-bluegray  round px-6 sm:px-16 py-12 sm:py-16  flex flex-col justify-center gap-24 ">
        {data.map((item) => (
          <div className="flex flex-row justify-between ">
            <div className=" flex flex-col justify-center gap-8 w-full lg:w-1/2  sm:pr-8">
              <h2 className="font-sans font-bold text-[#ffffff] text-4xl lg:text-4xl xl:text-5xl 2xl:text-6xl ">
                {item.title}
              </h2>
              <p className="font-sans font-light text-[#ffffff] text-sm lg:text-sm xl:text-base 2xl:text-xl text-start ">
                {item.text}
              </p>
            </div>

            <div className=" w-2/5 flex justify-end hidden lg:block">
              <img src={item.image} alt="busines men holding paper" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AboutInfo
