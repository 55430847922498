import React from 'react'
import logo from '../images/logo&icon/Logo.svg'
import { Link } from 'react-router-dom'
import { hizmetList } from '../constants'
import { NavLink } from 'react-router-dom'
import mail from '../images/logo&icon/mail.svg'
import phone from '../images/logo&icon/phone.svg'
import address from '../images/logo&icon/address.svg'
import whatsapp from '../images/logo&icon/whatsapp.svg'
import instagram from '../images/logo&icon/instagram.svg'
import facebook from '../images/logo&icon/facebook.svg'
import linkedin from '../images/logo&icon/linkedin.svg'
function Footer() {
  return (
    <div className="inset-x-0 bottom-0 min-h-60 round-t bg-bluegray grid justify-items-center mt-7 ">
      <div className=" grid gap-4 justify-center md:grid-cols-2 md:gap-4 xl:flex xl:flex-row xl:justify-between mt-12 w-screen px-28 pl-36">
        {/* logo-info-copyright */}
        <div className="w-60 ">
          <div className=" w-54 h-16  flex-none mr-6">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <p className="text-[#ffffffd4] font-sans font-thin ">
            Profesyonel danışmanlarımız, Amazon hesabı açma, banka hesabı
            oluşturma, şirket kurulumu ve daha fazlası konularında size yardımcı
            olmaya hazır.
          </p>
        </div>

        {/* hizmetlerimiz */}
        <div>
          <h2 className="text-2xl text-[#ffffff] underline underline-offset-8 decoration-4 decoration-orange under mb-2">
            Hizmetlerimiz
          </h2>
          <div className="grid gap-2">
            {hizmetList.map((item) => (
              <NavLink
                className=" font-sans font-thin text-[#ffffff] hover:font-normal w-28"
                key={item._id}
                to={item.link}
              >
                {item.title}
              </NavLink>
            ))}
          </div>
        </div>

        {/* bağlantılar */}
        <div className="hidden md:block ">
          <h2 className=" text-2xl text-[#ffffff] underline underline-offset-8 decoration-4 decoration-orange under mb-2">
            Bağlantılar
          </h2>
          <div className="grid gap-2 list-none">
            <li className=" font-sans font-thin text-[#ffffff] w-32">
              Satış Sözleşmesi
            </li>
            <li className=" font-sans font-thin text-[#ffffff] w-32">
              Çerez Politikası
            </li>
            <li className=" font-sans font-thin text-[#ffffff] w-32">
              Gizlilik Politikası
            </li>
          </div>
        </div>

        {/* iletişim */}
        <div>
          <div>
            <h2 className="text-2xl text-[#ffffff] underline underline-offset-8 decoration-4 decoration-orange under mb-2">
              İletişim Bilgileri
            </h2>
            <div className="grid gap-2 list-none">
              <div className="flex gap-4">
                <img className=" w-6" src={mail} alt="mail-icon" />
                <a
                  href="mailto:info@commersia.net"
                  className=" font-sans font-thin text-[#ffffff] w-32"
                >
                  info@commersia.net
                </a>
              </div>
              <div className="flex gap-4">
                <img className=" w-6" src={phone} alt="mail-icon" />
                <a
                  href="tel:+12523576161"
                  className=" font-sans font-thin text-[#ffffff] w-32"
                >
                  +1 994-339-8911
                </a>
              </div>
              <div className="flex gap-4">
                <img className=" w-6" src={address} alt="mail-icon" />
                <p className=" font-sans font-thin text-[#ffffff] w-42 ">
                  7901 4th St N STE 300 St. Petersburg, FL
                </p>
              </div>
            </div>
            {/* contact icons */}
            <div className="flex mt-12 justify-between  w-52 sm:w-44 ">
              <a href="http://wa.me/+12523576161" target="_blank">
                <img src={whatsapp} alt="whatsapp-icon" />
              </a>
              <a href="https://www.instagram.com" target="_blank">
                <img src={instagram} alt="instagram-icon" />
              </a>
              <a href="https://www.facebook.com" target="_blank">
                <img src={facebook} alt="facebook-icon" />
              </a>

              <a href="https://www.linkedin.com" target="_blank">
                <img src={linkedin} alt="linkedin-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className="text-[#ffffffcb] font-sans font-extralight text-sm mt-2">
        Copyright &copy; 2024 all rights reserved{' '}
      </p>
    </div>
  )
}

export default Footer
