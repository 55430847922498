import React, { useState } from 'react'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import { Form } from 'react-router-dom'
import phoneicon from '../images/contact/phone-icon.svg'
import mailicon from '../images/contact/mail-icon.svg'
import addressicon from '../images/contact/address-icon.svg'
import phoneDesign from '../images/logo&icon/phone-design-new.svg'
import phoneDesign2 from '../images/logo&icon/phone-design-2-new.svg'
import { motion } from 'framer-motion'
import Whatsapp from '../components/Whatsapp'
function Contact() {
  const sendEmail = (e) => {
    const val = true
    e.preventDefault()
    alert('Form Başarıyla Gönderildi!')
    if (val) {
      emailjs
        .sendForm('service_gwv77ea', 'template_y6r0dvb', Form.current, {
          publicKey: 'LRRtyvtLSbBCv1E7Y',
        })
        .then(
          () => {
            console.log('SUCCESS!')
          },
          (error) => {
            console.log('FAILED...', error.text)
          }
        )
    }
  }

  const phoneDash = () => {
    var tele = document.querySelector('#phone')

    tele.addEventListener('keyup', function (e) {
      var temp = tele.value.split('-').join('')
      var foo
      if (temp.length <= 10) {
        foo = temp
        if (foo) {
          foo = foo.match(new RegExp('.{1,4}$|.{1,3}', 'g')).join('-')

          tele.value = foo
        }
      } else {
        tele.value = foo
      }
    })
  }
  return (
    <motion.div
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <div className="absolute w-full flex justify-end hidden md:flex">
        <img src={phoneDesign} alt="" className=" md:w-32 lg:w-52 " />
      </div>
      <div className="bg-bluegray w-auto h-auto pb-12 round-b shadow-2xl px-6 sm:px-16 py-12 sm:py-16 ">
        <div className="flex flex-col ">
          <h1 className="font-sans font-medium text-[#ffffff] text-5xl w-full text-center z-40">
            Bizimle İletişime Geçin!
          </h1>

          <div className="  w-full px-6 sm:px-16 py-12 sm:py-16 flex flex-col justify-center gap-24  ">
            <div className="flex flex-row justify-around ">
              <Form
                ref={Form}
                onSubmit={sendEmail}
                className="grid grid-cols-1 w-1-2  lg:w-1/3 gap-8 rounded-3xl z-40"
              >
                <input
                  className=" rounded-2xl pl-3 p-1 text-xl "
                  placeholder="Ad"
                  type="text"
                  name="user_name"
                  required
                />

                <input
                  className="rounded-2xl pl-3 p-1 text-xl"
                  placeholder="Soyad"
                  type="text"
                  name="user_surname"
                  required
                />

                <input
                  className="rounded-2xl pl-3 p-1 text-xl"
                  placeholder="E-posta"
                  type="email"
                  name="user_email"
                  required
                />
                <input
                  className="rounded-2xl pl-3 p-1 text-xl"
                  placeholder="Telefon: 5XX-XXX-XXXX"
                  minLength={12}
                  maxLength={12}
                  required
                  id="phone"
                  onChange={phoneDash}
                  type="tel"
                  pattern={
                    '[0-9]{3}-[0-9]{3}-[0-9]{4}' || '[0-9]{3}-[0-9]{3}-[0-9]{4}'
                  }
                  name="user_phone"
                />

                <select
                  className="rounded-2xl pl-3 p-1 text-xl font-sans font-normal text-[#9ca3af]"
                  name="user_packet"
                  placeholder="Paket"
                  id="1"
                >
                  <option value="Amerika Paketi -Illionis &sim; $999">
                    Amerika Paketi -Illionis &sim; $999
                  </option>
                  <option value="Amerika Paketi -Florida &sim; $949">
                    Amerika Paketi -Florida &sim; $949
                  </option>
                  <option value="Amerika Paketi -Wyoming &sim; $899">
                    Amerika Paketi -Wyoming &sim; $899
                  </option>
                  <option value="ITIN Hizmeti &sim; $800">
                    ITIN Hizmeti &sim; $799
                  </option>
                  <option value="Marka Tescili -USA &sim; $999">
                    Marka Tescili -USA &sim; $999
                  </option>
                  <option value="Marka Tescili -CANADA &sim; $599">
                    Marka Tescili -CANADA &sim; $599
                  </option>
                  <option value="Marka Tescili -UK &sim; $599">
                    Marka Tescili -UK &sim; $599
                  </option>
                  <option value="Marka Tescili -EU &sim; $1499">
                    Marka Tescili -EU &sim; $1499
                  </option>
                  <option value="Marka Tescili -GERMANY &sim; $699">
                    Marka Tescili -GERMANY &sim; $699
                  </option>
                  <option value="Marka Tescili -AU &sim; $699">
                    Marka Tescili -AU &sim; $699
                  </option>
                  <option value="Resale Sertifika-(NJ-IL-FL) &sim; $100">
                    Resale Sertifika -(NJ-IL-FL) &sim; $129
                  </option>
                </select>

                <textarea
                  className="rounded-2xl pl-3 p-1 text-xl h-44"
                  placeholder="Bir mesaj yazın.."
                  name="user_message"
                />
                <div className="flex flex-row justify-center">
                  <button className=" bg-orange round  hover:bg-red  w-52 h-14 justify-center ">
                    <input
                      className="font-sans font-medium text-[#ffffff] text-2xl w-full h-full cursor-pointer"
                      type="submit"
                      value="Gönder"
                    />
                  </button>
                </div>
              </Form>

              <div className="flex flex-col justify-center gap-4 w-1/3  hidden lg:flex">
                <div className="flex flex-row justify-start bg-[#ffffff] rounded-xl p-2 pr-4">
                  <img
                    src={mailicon}
                    alt="mail-icon"
                    className=" w-8 xl:w-10"
                  />
                  <div className=" w-full flex flex-col justify-center pl-1">
                    <p className="font-sans font-normal text-[#9ca3af] lg:text-lg xl:text-xl ">
                      info@commersia.net
                    </p>
                  </div>
                </div>
                <div className="flex flex-row justify-start bg-[#ffffff] rounded-xl p-2 pr-4">
                  <img
                    src={phoneicon}
                    alt="phone-icon"
                    className=" w-8 xl:w-10"
                  />
                  <div className=" w-full flex flex-col justify-center ">
                    <p className="font-sans font-normal text-[#9ca3af] lg:text-lg xl:text-xl  ">
                      +1 994-339-8911
                    </p>
                  </div>
                </div>
                <div className="flex flex-row justify-start bg-[#ffffff] rounded-xl p-2 pr-4">
                  <img
                    src={addressicon}
                    alt="address-icon"
                    className=" w-8 xl:w-10"
                  />

                  <div className=" w-full flex flex-col justify-center ">
                    <p className="font-sans font-normal text-[#9ca3af] lg:text-lg xl:text-xl  ">
                      7901 4th St N STE 300 St. Petersburg, FL
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute flex  justify-center top-[704px] hidden md:flex ">
          <img src={phoneDesign2} alt="" className="md:w-32 lg:w-52 " />
        </div>
      </div>
      <Whatsapp />
    </motion.div>
  )
}

export default Contact
