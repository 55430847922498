import React from 'react'
import { NavLink } from 'react-router-dom'
import about1 from '../../images/about/about-1.svg'
function AboutHero() {
  return (
    <div className="bg-bluegray w-auto h-auto pb-12 round-b shadow-2xl ">
      <div className=" flex flex-row justify-center md:justify-between p-10">
        <div className="flex flex-col gap-8 justify-center w-full md:w-7/12 pr-8 ">
          <h1 className="font-sans font-medium text-2xl  md:text-3xl lg:text-5xl xl:text-6xl 2xl:text-7xl text-[#ffffff]">
            Commersia Danışmanlık
          </h1>
          <p className="font-sans font-light text-xs  md:text-sm lg:text-md xl:text-lg 2xl:text-xl text-[#ffffff]">
            Commersia ekibi olarak, Amazon platformunda 5 yıldır hizmet
            vermekteyiz. Müşterilerimize Amerika'da şirket kurulumu, Amazon
            hesabı açma, Mercury ve Payoneer hesabı açma, ve Resale sertifikası
            alma gibi çeşitli hizmetler sunuyoruz. Tecrübemiz ve uzmanlığımızla
            sektördeki gücümüzü her geçen gün arttırarak, müşterilerimize en iyi
            hizmeti sunmaya devam ediyoruz.
          </p>
          <div className="w-full flex flex-row justify-start">
            <NavLink
              to="/"
              className="text-[#ffffff] text-sm md:text-md lg:text-lg xl:text-2xl"
            >
              <button className=" bg-orange round  hover:bg-red w-40 h-10  md:w-44 md:h-10  lg:w-52 lg:h-14  xl:w-64 xl:h-16 2xl:w-80 2xl:h-16 justify-center ">
                Hemen İletişime Geç!
              </button>
            </NavLink>
          </div>
        </div>

        <div className="grid grid-cols-1 w-4/12 justify-items-center hidden md:block ">
          <img src={about1} alt="5 business people" className="pb-4 w-full" />
          <div className="flex flex-row justify-around w-full ">
            <div className="grid grid-cols-1 w-24">
              <p className="font-sans font-bold text-xl  md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-[#ffffff]">
                5+
              </p>
              <p className="font-sans font-extralight text-xs lg:text-sm text-[#ffffff] pl-[3px] pb-8">
                Yılı Aşkın Deneyim
              </p>
            </div>
            <div className="grid grid-cols-1 w-24">
              <p className="font-sans font-bold text-xl  md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-[#ffffff]">
                150+
              </p>
              <p className="font-sans font-extralight text-xs lg:text-sm text-[#ffffff] pl-[3px] pb-8 w-28">
                Amerika Şirket kurulumu
              </p>
            </div>
            <div className="grid grid-cols-1 w-24">
              <p className="font-sans font-bold text-xl  md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-[#ffffff] ">
                80+
              </p>
              <p className=" font-sans font-extralight text-xs lg:text-sm text-[#ffffff] pl-[3px] pb-8 w-28">
                İngiltere Şirket kurulumu
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutHero
