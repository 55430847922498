import React from 'react'
import { useState, useEffect } from 'react'
import whatsapp from '../images/logo&icon/whatsappredirect.svg'
import { motion } from 'framer-motion'
function Whatsapp() {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setLoading(true)
    }, 900)
  }, [])
  return (
    <div className="w-full flex flex-row justify-end p-8 z-50 sticky bottom-0">
      {loading && (
        <motion.div
          className="w-full flex flex-row justify-end z-50 sticky bottom-0"
          initial={{ y: -10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <a href="http://wa.me/+905456045219" target="_blank">
            <button className="transition ease-in-out delay-100 hover:scale-110 duration-300">
              <img
                src={whatsapp}
                alt=""
                className=" w-18 sm:w-24 transition hover:ease-in-out hover:duration-75"
              />
            </button>
          </a>
        </motion.div>
      )}
    </div>
  )
}

export default Whatsapp
